import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, TextField, Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import '../App.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRequestOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://178.63.115.237:25574/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            if (response.ok) {
                setSnackbarMessage(data.message || 'OTP sent successfully');
                setStep(2);
            } else {
                setSnackbarMessage(data.error || 'Failed to send OTP');
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error requesting OTP:', error);
            setSnackbarMessage('Error requesting OTP');
            setSnackbarOpen(true);
        }
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://178.63.115.237:25574/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, otp })
            });
            const data = await response.json();
            if (response.ok) {
                setSnackbarMessage(data.message || 'OTP verified successfully');
                setStep(3);
            } else {
                setSnackbarMessage(data.error || 'Invalid or expired OTP');
                setOpenError(true);
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setSnackbarMessage('Error verifying OTP');
            setSnackbarOpen(true);
        }
    };


    const handleChangePassword = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        fetch(`http://157.90.144.7:4562/change-password?email=${email}&newPassword=${newPassword}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to change password');
                }
                return response.text();
            })
            .then(result => {
                // Handle success
                setSnackbarMessage('Password updated successfully');
                setOpenSuccess(true);
            })
            .catch(error => {
                console.error('Error changing password:', error);
                setErrorMessage('Error changing password');
                setOpenError(true);
            })
            .finally(() => {
                setSnackbarOpen(true);
            });
    };

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
        navigate('/authentication/sign-in'); // Navigate to sign-in page
    };

    const handleCloseError = () => {
        setOpenError(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{ backgroundImage: 'url(assets/images/bg-sign-in-cover.jpeg)', backgroundSize: 'cover' }}
        >
            <Card sx={{ p: 4, maxWidth: 400 }}>
                <Box textAlign="center" mb={2}>
                    <Typography variant="h4">
                        {step === 1 ? 'Forgot Password' : step === 2 ? 'Verify OTP' : 'Reset Password'}
                    </Typography>
                    <Typography variant="subtitle1">
                        {step === 1 ? 'Enter your email to receive an OTP' : step === 2 ? 'Enter the OTP sent to your email' : 'Enter your new password'}
                    </Typography>
                </Box>
                <Box component="form" onSubmit={step === 1 ? handleRequestOTP : step === 2 ? handleVerifyOTP : handleChangePassword}>
                    {step === 1 && (
                        <TextField
                            label="Email"
                            variant="standard"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                        />
                    )}
                    {step === 2 && (
                        <TextField
                            label="OTP"
                            variant="standard"
                            fullWidth
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            margin="normal"
                        />
                    )}
                    {step === 3 && (
                        <TextField
                            label="New Password"
                            variant="standard"
                            fullWidth
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                    )}
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        {step === 1 ? 'Request OTP' : step === 2 ? 'Verify OTP' : 'Reset Password'}
                    </Button>
                </Box>
                {step === 2 && (
                    <Box textAlign="center" mt={2}>
                        <Typography variant="body2">
                            Remember your password?{' '}
                            <Typography component="a" href="/authentication/sign-in" color="primary">
                                Sign In
                            </Typography>
                        </Typography>
                    </Box>
                )}
            </Card>

            {/* Success Modal */}
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                PaperProps={{ style: { background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: 'white', borderRadius: '8px' } }}
            >
                <DialogTitle>{"Success"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'white' }}>
                        Password updated successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} style={{ color: 'white' }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Modal */}
            <Dialog
                open={openError}
                onClose={handleCloseError}
                PaperProps={{ style: { background: 'linear-gradient(135deg, #ff5f6d 0%, #ffc371 100%)', color: 'white', borderRadius: '8px' } }}
            >
                <DialogTitle>{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'white' }}>
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseError} style={{ color: 'white' }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default ForgotPassword;
