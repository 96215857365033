import React, { useState } from 'react';
import { Container, Paper, Box, TextField, Button, Snackbar } from '@mui/material';
import '../App.css';

const UserProfile = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const email = localStorage.getItem('email');

    const handleChangePassword = async () => {
        try {
            console.log('Sending request to:', 'http://localhost:4526/change-password');
            const response = await fetch('http://localhost:4526/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                })
            });
            const data = await response.json();
            if (response.ok) {
                setSnackbarMessage(data.message || 'Password changed successfully');
            } else {
                setSnackbarMessage(data.error || 'Failed to change password');
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error changing password:', error);
            setSnackbarMessage('Error changing password');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <main className="content">
            <div className="toolbar" />
            <Container>
                <Paper>
                    <Box p={2}>
                        <TextField
                            label="Old Password"
                            type="password"
                            fullWidth
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="New Password"
                            type="password"
                            fullWidth
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleChangePassword}
                            style={{ marginTop: '10px' }}
                        >
                            Change Password
                        </Button>
                    </Box>
                </Paper>
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </main>
    );
};

export default UserProfile;
